import * as React from "react"
import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import {createNavItems} from "../utils/nav-items-util";
import {graphql} from "gatsby";
import SEO from "../components/seo";

type Props = {
  data: {
    prismicTerms: {
      uid: string
      url: string
      data: {
        terms: {
          html: string
        }
      }
    }
  }
}

const TermsOfUsePage: React.FC<Props> = ({data}) => {
  return (
    <Layout navItems={createNavItems({current: "about"})}>
      <SEO title="Terms of use"/>
      <PageWrapper className={"mt-16 mb-16"}>
        <div className="prose" dangerouslySetInnerHTML={{__html: data.prismicTerms.data.terms.html}}/>
      </PageWrapper>
    </Layout>
  )
};

export default TermsOfUsePage

export const query = graphql`
  query TermOfUsePage {
    prismicTerms {
      uid
      url
      data {
        terms {
          html
          text
        }        
      }
    }
  }
`;
